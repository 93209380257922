import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Input, Switch, Select, InputNumber, Radio } from "antd";
import moment from "moment";
import { formLayout, mode as Mode, AppStatus } from "@/utils/enum";
import { selectUser, getUsers } from "@/store/slice/user";
import { selectAppGroup, getAppGroups } from "@/store/slice/appGroup";
import { getAppsSearch, selectApp } from "@/store/slice/app";
import SearchSelect from "@/components/SearchSelect";
import Spin from "@/components/Spin";
import RechargeRateFormList from "@/components/RechargeRateFormList";

const { Option } = Select;
const AddEdit = ({
  mode = "add",
  rateType = 0, // type: 0大額(單一) 1小額(階層)
  visible = false,
  data = null,
  loading = false,
  onOk = () => {},
  onCancel = () => {},
}) => {
  const { list: users } = useSelector(selectUser);
  const [form] = Form.useForm();
  const handleOk = async () => {
    form.validateFields().then(async formModel => {
      if (!formModel) return;
      const handleTimeRateToStr = list =>
        list.map(({ start_time, end_time, ...ll }) => ({
          ...ll,
          start_time: start_time.format("HH:mm"),
          end_time:
            end_time.format("HH:mm") === "23:59"
              ? "24:00"
              : end_time.format("HH:mm"),
        }));
      const { developer_id, recharge_time_class_rate, fee, ...props } =
        formModel;
      await onOk({
        ...props,
        developer_id,
        developer_name: users.find(i => i.id === developer_id)?.name,
        fee: fee ? fee.toString() : "0",
        ...(recharge_time_class_rate && {
          recharge_time_class_rate: handleTimeRateToStr(
            recharge_time_class_rate,
          ),
        }),
      });
    });
  };

  const handleTimeRateToMoment = list =>
    list.map(({ start_time, end_time, ...ll }) => ({
      ...ll,
      start_time: moment(start_time, "HH:mm"),
      end_time: moment(end_time === "24:00" ? "23:59" : end_time, "HH:mm"),
    }));
  const onSelectUpperLayer = async data => {
    form.setFieldsValue({
      is_upperLayer: false,
      recharge_rate: data.recharge_rate,
      recharge_rate_type: data.recharge_rate_type,
      recharge_class_rate: data.recharge_class_rate,
      recharge_time_class_rate:
        data.recharge_time_class_rate &&
        handleTimeRateToMoment(data.recharge_time_class_rate),
    });
  };

  const onValuesChange = changeObj => {
    if ("upper_layer_id" in changeObj && !changeObj.upper_layer_id) {
      form.setFieldsValue({
        recharge_rate: null,
        is_upperLayer: true,
        recharge_class_rate: null,
      });
    }
  };

  const onSelectAppGroup = ({ name }) => {
    form.setFieldsValue({ group_name: name });
  };

  const addModeInit = () => {
    let initForm = {
      status: 0,
      is_active: true,
      is_upperLayer: true,
      recharge_rate_type: rateType,
      recharge_class_rate: null,
      recharge_time_class_rate: null,
    };
    form.setFieldsValue(initForm);
  };

  const editModeInit = () => {
    form.setFieldsValue({
      ...data,
      is_upperLayer: data.upper_layer_id === data.id,
      ...(data.recharge_time_class_rate && {
        recharge_time_class_rate: handleTimeRateToMoment(
          data.recharge_time_class_rate,
        ),
      }),
    });
  };
  useEffect(() => {
    if (visible) {
      mode === "add" && addModeInit();
      mode === "edit" && editModeInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, data]);

  return (
    <Modal
      destroyOnClose={true}
      title={`${Mode[mode]}商户管理`}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      cancelText="取消"
      okText="送出"
      confirmLoading={loading}
      width={700}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          form={form}
          onValuesChange={onValuesChange}
          preserve={false}
        >
          <Form.Item
            name="is_upperLayer"
            label="是否为超级商户"
            valuePropName="checked"
            hidden
          >
            <Switch />
          </Form.Item>
          <Form.Item name="is_active" label="是否启用" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item name="status" label="状态">
            <Select>
              {Object.keys(AppStatus).map(i => (
                <Option value={Number(i)} key={i}>
                  {AppStatus[i]}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {mode === "add" && (
            <Form.Item name="upper_layer_id" label="上级商户">
              <SearchSelect
                params={{ per_page: 999 }}
                action={getAppsSearch}
                selector={selectApp}
                onSelect={onSelectUpperLayer}
                selectorMetaKey="searchMeta"
                searchKey="name"
                val="id"
                label={i => `${i.id} ${i.name}`}
                allowClear
              />
            </Form.Item>
          )}
          <Form.Item
            name="name"
            label="名称"
            rules={[{ required: true, message: "请输入名称" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name_cn"
            label="姓名"
            rules={[{ required: true, message: "请输入姓名" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="note" label="备注">
            <Input />
          </Form.Item>
          {mode === "add" && (
            <Form.Item
              name="developer_id"
              label="使用者ID"
              rules={[{ required: true, message: "请输入使用者ID" }]}
            >
              <SearchSelect
                action={getUsers}
                params={{ is_developer: 1 }}
                selector={selectUser}
                searchKey="username"
                val="id"
                label={i => `${i.id} ${i.username}`}
              />
            </Form.Item>
          )}
          <Form.Item
            name="callback_url"
            label="回调网址"
            rules={[{ required: true, message: "请输入回调网址" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="group_id" label="群组费率">
            <SearchSelect
              action={getAppGroups}
              selector={selectAppGroup}
              onSelect={onSelectAppGroup}
              searchKey="name"
              val="id"
              label={i => `${i.id} ${i.name}`}
            />
          </Form.Item>
          <Form.Item name="group_name" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="fee" label="第三方费率">
            <InputNumber step="0.01" />
          </Form.Item>
          <Form.Item name="info" label="info">
            <Input />
          </Form.Item>
          {/* <Form.Item name="withdraw_rate" label="代付费率">
            <InputNumber step="1" />
          </Form.Item> */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <Form.Item name="recharge_rate_type" label="代收费率类型">
                <Radio.Group
                  disabled={mode === "add" || !getFieldValue("is_upperLayer")}
                >
                  <Radio value={0}>单一费率</Radio>
                  <Radio value={1}>阶层费率</Radio>
                  <Radio value={2}>时间费率</Radio>
                </Radio.Group>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item name="recharge_rate" label="代收费率">
            <InputNumber step="1" />
          </Form.Item>

          <Form.Item
            shouldUpdate
            wrapperCol={{
              span: formLayout.wrapperCol.span + formLayout.labelCol.span,
            }}
          >
            <RechargeRateFormList
              name="recharge_class_rate"
              form={form}
              title="阶层费率"
            />
          </Form.Item>

          <Form.Item
            shouldUpdate
            wrapperCol={{
              span: formLayout.wrapperCol.span + formLayout.labelCol.span,
            }}
          >
            <RechargeRateFormList
              showTimeRange={true}
              name="recharge_time_class_rate"
              form={form}
              title="时段费率"
              alertMsg={
                <>
                  单个时段不可跨日，如要设定跨日请设定两个时段
                  <br />
                  例如[20:00]至隔天[08:00]，必須設定 [20:00]到[23:59]
                  以及[00:00]到[08:00]兩組時間
                </>
              }
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
export default AddEdit;
